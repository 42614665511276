import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import "../css/blogDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ArrowLeftOutlined } from "@ant-design/icons";

const BlogDetails = () => {
  const [blogs, setBlogs] = useState([]);
  const [loader, setLoader] = useState(true);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://tbuhx03.truliacare.com/cms/api/truliacare-blogs?populate=*"
        );
        setBlogs(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error in Fetching Blogs ", error);
      } finally {
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
    };
    fetchBlogs();
  }, []);

  const formatSlug = (title) => {
    return title.split(" ").join("_").toLowerCase();
  };

  const blogData = blogs.find((b) => formatSlug(b.attributes.blogUrl) === slug);
  if (!blogData) {
    return <p>Blog not found.</p>;
  }

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    metaAbstract,
    metaCanonical,
  } = blogData?.attributes;

  const handleReturn = () => {
    navigate("/blogs");
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Layout
      title={metaTitle}
      description={metaDescription}
      keywords={metaKeywords}
      abstract={metaAbstract}
      canonical={metaCanonical}
    >
      <div className="container mt-5 blog-container">
        <div
          className="d-flex gap-2 ms-4 blog-back-icon"
          style={{ cursor: "pointer" }}
          onClick={handleReturn}
        >
          <span>
            <ArrowLeftOutlined />
          </span>
          <span style={{fontSize:"2.3rem"}}>Blogs</span>
        </div>
        <div className="blogDetails-wrapper">
          {loader && (
            <div className="blog-details-loader">
              <div className="loading__dot"></div>
              <div className="loading__dot"></div>
              <div className="loading__dot"></div>
            </div>
          )}
          {!loader && (
            <article className="card blog-details-card">
              <div className="card-body blog-card-body">
                <h1 className="card-title blog-card-title">
                  {blogData?.attributes?.blogTitle}
                  <span className="blogDetails-date">
                    {formatDate(blogData?.attributes?.publishedAt)} | Local Time
                  </span>
                </h1>
                <img
                  src={`https://tbuhx03.truliacare.com/cms${blogData?.attributes?.coverImage?.data?.attributes?.url}`}
                  alt="Blog"
                  className="blog-detail-image"
                />
                <p className="card-text blog-card-desc">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {blogData?.attributes?.blogContent}
                  </ReactMarkdown>
                </p>
              </div>
            </article>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default BlogDetails;

import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../css/blogs.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const meta = {
  title: "Blogs",
  description:
    "TruliaCare is an award-winning healthcare solutions and services company with a team of experts who assist healthcare providers and vendors with advanced healthcare technology. Kratin simplifies health information beyond portals by providing a unified care experience across the care continuum through its TruliaCare solution suite, UHX platform, and Innovation Lab.",
  keywords:
    "Hospice solutions, homecare technology, hospice compliance, HOPE Tool, 2025 Hospice Final Rule, patient outcomes, healthcare innovations, hospice care improvements, healthcare regulations. Care Companion, Clinical Assistant, Event driven, real time, intelligent workflows, Integrates with your EHR & other systems with 200+ connectors, Ready to use digital care components, intelligent Digital Care Transformation, Innovation, mHealth, Unified Health Experience, UHX, HealthCare Product Innovation, Patient Engagement, healthcare solutions, homecare, hospice care, hospice solutions.",
  abstract:
    "TruliaCare is an award-winning healthcare solutions and services company with a team of experts who assist healthcare providers and vendors with advanced healthcare technology. Kratin simplifies health information beyond portals by providing a unified care experience across the care continuum through its TruliaCare solution suite, UHX platform, and Innovation Lab.",
  canonical: "https://www.truliacare.com/blogs",
};

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://tbuhx03.truliacare.com/cms/api/truliacare-blogs?populate=*"
        );
        console.log(response.data)
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();
  }, []);

  const formatSlug = (title) => {
    return title.split(" ").join("_").toLowerCase();
  };

  const handleContinue = (title) => {
    const slug = formatSlug(title);
    navigate(`/blogs/${slug}`);
  };

  const handleImageLoad = () => {
    setLoadingImages(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      keywords={meta.keywords}
      abstract={meta.abstract}
      canonical={meta.canonical}
    >
      <div className="blogs-overview">
        <div className="blogs-title-wrapper">
          <div className="blogs-title">
            <h2>Blogs</h2>
          </div>
          <div className="blog-card-wrapper">
            {blogs?.data
              ?.slice()
              .sort(
                (a, b) =>
                  new Date(b.attributes.published) -
                  new Date(a.attributes.published)
              )
              .map((data) => {
                return (
                  <div key={data.id} className="card blog-card">
                    <div className="blog-card-content">
                      {loadingImages && (
                        <div className="loading">
                          <div className="loading__dot"></div>
                          <div className="loading__dot"></div>
                          <div className="loading__dot"></div>
                        </div>
                      )}
                      <img
                        src={`https://tbuhx03.truliacare.com/cms${data?.attributes.coverImage.data?.attributes.url}`}
                        alt="blog "
                        onLoad={handleImageLoad}
                        className="blog-card-image"
                        style={{ display: loadingImages ? "none" : "block" }}
                      />
                      <div className="blog-card-body">
                        <h4
                          className="card-title blog-card-heading"
                          onClick={() =>
                            handleContinue(data.attributes.blogUrl)
                          }
                        >
                          {data?.attributes.blogTitle}
                        </h4>
                        <h6 className="mb-2 blog-date">
                          {formatDate(data?.attributes.published)} | Local Time
                        </h6>
                        <p className="card-text blog-description">
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {`${data?.attributes.blogContent.slice(0, 380)}...`}
                          </ReactMarkdown>
                          {data?.attributes?.tags && (
                            <div className="blog-tags">
                              {data.attributes.tags.join(" | ")}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blogs;

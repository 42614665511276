import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CareCoordination from "./pages/Solution/CareCoordination";
import CareCompanion from "./pages/Solution/CareCompanion";
import Hospice from "./pages/Solution/Hospice";
import Dicom from "./pages/Solution/Dicom";
import InnovationPage from "./pages/Innovation/InnovationPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Careers from "./pages/Careers";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/care-coordination" element={<CareCoordination />} />
      <Route path="/care-companion" element={<CareCompanion />} />
      <Route path="/hospice-homecare" element={<Hospice />} />
      <Route path="/dicom-ingester" element={<Dicom />} />
      <Route path="/innovation-lab" element={<InnovationPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/careers" element={<Careers />} />
    </Routes>
  );
}

export default App;

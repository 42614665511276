import React, { useEffect, useState } from "react";
import logo from "../assets/logo/truliacare_logo.svg";
import "../css/header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setMobileMenu(false);
  };

  const path = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      path.startsWith("/care-coordination") ||
      path.startsWith("/care-companion") ||
      path.startsWith("/hospice") ||
      path.startsWith("/dicom")
    ) {
      setActiveTab("solutions");
    } else if (path.startsWith("/careers")) {
      setActiveTab("careers");
    } else if (path.startsWith("/innovation-lab")) {
      setActiveTab("innovation");
    } else if (path.startsWith("/blogs")) {
      setActiveTab("blogs");
    }
  }, [location]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sections = ["home", "solutions", "innovation", "about", "contact"];

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        const { offsetTop, offsetHeight } = element;
        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          setActiveTab(section);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (tab, id) => {
    handleTabClick(tab);
    if (path !== "/") {
      navigate("/", { state: { scrollTo: id } });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <nav
      className="navbar navbar-expand-lg bg-white sticky-top"
      style={{ borderBottom: "3px solid #dedede" }}
    >
      <div className="container-fluid navbar-container">
        <a className="navbar-brand" href="/">
          <img
            src={logo}
            alt="truliacare_logo"
            style={{ width: "160px" }}
            className="navbar-img"
          />
        </a>
        <button
          className="navbar-toggler"
          style={{ border: "none" }}
          type="button"
          onClick={toggleMobileMenu}
          aria-controls="navbarSupportedContent"
          aria-expanded={mobileMenu}
          aria-label="Toggle navigation"
        >
          <i className="fa-solid fa-bars navbar-toggler-icon mobile-icon"></i>
        </button>
        <div
          className={`collapse navbar-collapse menu-container ${
            mobileMenu ? "show" : ""
          }`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item nav-custom">
              <Link
                className={`nav-link ${
                  activeTab === "home" ? "active-item" : ""
                }`}
                aria-current="page"
                to="/"
                onClick={() => handleTabClick("home")}
              >
                Home
              </Link>
            </li>
            <li className="nav-item nav-custom">
              <a
                className={`nav-link ${
                  activeTab === "solutions" ? "active-item" : ""
                }`}
                href="#solutions"
                onClick={() => handleNavigation("solutions", "solutions")}
              >
                Solutions
              </a>
            </li>
            <li className="nav-item nav-custom">
              <a
                className={`nav-link ${
                  activeTab === "innovation" ? "active-item" : ""
                }`}
                href="#innovation"
                onClick={() => handleNavigation("innovation", "innovation")}
              >
                Innovation Lab
              </a>
            </li>
            <li className="nav-item nav-custom">
              <Link
                className={`nav-link ${
                  activeTab === "careers" ? "active-item" : ""
                }`}
                to="/careers"
                onClick={() => handleNavigation("careers", "careers")}
              >
                Careers
              </Link>
            </li>
            <li className="nav-item nav-custom">
              <Link
                className={`nav-link ${
                  activeTab === "blogs" ? "active-item" : ""
                }`}
                to="/blogs"
                onClick={() => handleNavigation("blogs", "blogs")}
              >
                Blogs
              </Link>
            </li>
            <li className="nav-item nav-custom">
              <a
                className={`nav-link ${
                  activeTab === "about" ? "active-item" : ""
                }`}
                href="#about"
                onClick={() => handleNavigation("about", "impact")}
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "contact" ? "active-item" : ""
                }`}
                href="#contact"
                onClick={() => handleNavigation("contact", "contact")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
